import React, { useContext, useEffect } from "react";
import SEO from "../components/SEO";
import { logEvent } from "@src/utils/logClient";
import { useStartExperimentWhenInAudience } from "@src/utils/experiment";
import { AppDataContext } from "@src/components/AppDataContext";
import { ExternalSwitches } from "@src/utils/experiments/constants";
import HeroB from "@src/components/partials/index-2024/HeroB";
import HeroCarousel from "@src/components/partials/index-2024/HeroCarousel";
import TeacherCarousel from "@src/components/partials/index-2024/TeacherCarousel";
import SchoolwidePositivity from "@src/components/partials/index-2024/SchoolwidePositivity";
import Privacy from "@src/components/partials/index-2024/Privacy";
import BigIdeasCarousel from "@src/components/partials/index-2024/BigIdeasCarousel";
import { Helmet } from "react-helmet";
import getLocale from "@src/utils/getLocale";

const IndexPage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const {
    data: { loadingSession },
  } = useContext(AppDataContext);
  const userLanguage = getLocale(pageContext);

  useEffect(() => {
    const logAfterSession = async () => {
      if (!loadingSession) {
        logEvent({ eventName: "web.external_page.page_view_variant_b" });
      }
    };
    logAfterSession();
  }, [loadingSession]);

  useStartExperimentWhenInAudience(ExternalSwitches.WEB_EXTERNAL_HOMEPAGE_EXPERIMENT_2024, {
    isInAudience: userLanguage === "en-us",
  });

  return (
    <>
      <SEO noindex={true} />
      <Helmet>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/DynaPuff/stylesheet.css"></link>
      </Helmet>
      <HeroB />
      <HeroCarousel />
      <TeacherCarousel />
      <SchoolwidePositivity />
      <Privacy />
      <BigIdeasCarousel />
    </>
  );
};
export default IndexPage;
